<template>
  <div style="height: 100%">
    <el-card>
      <template #header>
        <small>
          {{
            !travel
              ? "Nenhum veículo em aberto"
              : `Viagem em aberto para o veículo ${travel?.car?.name}`
          }}
        </small>
      </template>
      <el-row
        type="flex"
        justify="center"
        style="min-height: 28px; align-items: center"
      >
        <el-button
          type="success"
          :disabled="!!travel"
          @click="() => $router.push(`/veiculos-controle-executar`)"
          >Iniciar viagem</el-button
        >
        <el-button
          type="danger"
          :disabled="!travel"
          @click="
            () => $router.push(`/veiculos-controle-executar/${Travel.car?.uid}`)
          "
          >Terminar viagem</el-button
        >
      </el-row>
    </el-card>

    <el-card v-loading="!supplies">
      <template #header>
        <el-row
          type="flex"
          justify="start"
          style="min-height: 28px; align-items: center"
        >
          <small class="card-header">MEUS ABASTECIMENTOS</small>
        </el-row>
      </template>
      <el-row type="flex" justify="center" align="center" class="mx-auto">
        <el-col>
          <el-result
            v-if="hasErrorSupplies"
            icon="error"
            title="Ocorreu um erro"
            sub-title="Não foi possível carregar suas ordens de abastecimento"
          >
            <template #extra>
              <el-button
                type="primary"
                @click="() => fetchMySupplyOrders()"
                size="medium"
                >Tentar novamente</el-button
              >
            </template>
          </el-result>
          <el-empty
            v-else-if="!Supplies?.length"
            description="Nenhuma ordem de abastecimento ativa"
          ></el-empty>
          <el-row
            type="flex"
            :loading="!supplies"
            justify="space-between"
            v-else
            v-for="l in Supplies"
            :key="l"
          >
            <small class="mx-1 item-text">{{ l?.car?.name }}</small>
            <el-button
              type="primary"
              icon="el-icon-arrow-right"
              @click="
                () =>
                  $router.push(`/abastecimento/executar/${l?.execution?.uid}`)
              "
              iconOnly
            ></el-button>
            <el-divider></el-divider>
          </el-row>
        </el-col>
      </el-row>
    </el-card>
    <el-card v-loading="!orders">
      <template #header>
        <el-row
          type="flex"
          justify="start"
          style="min-height: 28px; align-items: center"
        >
          <small class="card-header">MINHAS ENTREGAS</small>
        </el-row>
      </template>
      <el-result
        v-if="hasErrorOrders"
        icon="error"
        title="Ocorreu um erro"
        sub-title="Não foi possível carregar os pedidos para entrega"
      >
        <template #extra>
          <el-button type="primary" @click="() => fetchMyOrders()" size="medium"
            >Tentar novamente</el-button
          >
        </template>
      </el-result>
      <el-empty
        v-else-if="!Orders?.length"
        description="Nenhum pedido pronto para entrega"
      ></el-empty>
      <el-row type="flex" justify="space-between" v-for="l in Orders" :key="l">
        <div class="mx-1 item-text">
          {{ l?.client?.legal?.social_name || l?.client?.name }}
        </div>
        <el-button
          type="primary"
          icon="el-icon-arrow-right"
          @click="() => confirmAndCompleteDelivery(l)"
          iconOnly
        ></el-button>
        <el-divider></el-divider>
      </el-row>
    </el-card>
    <el-card v-loading="!logs">
      <template #header>
        <el-row
          type="flex"
          justify="start"
          style="min-height: 28px; align-items: center"
        >
          <small class="card-header">ÚLTIMAS AÇÕES</small>
        </el-row>
      </template>
      <el-result
        v-if="hasErrorLogs"
        icon="error"
        title="Ocorreu um erro"
        sub-title="Não foi possível carregar as suas últimas ações"
      >
        <template #extra>
          <el-button type="primary" @click="() => fetchMyLogs()" size="medium"
            >Tentar novamente</el-button
          >
        </template>
      </el-result>
      <el-empty
        v-else-if="!Logs?.length"
        description="Nenhuma ação foi registrada"
      ></el-empty>
      <el-row type="flex" justify="space-between" v-for="l in Logs" :key="l">
        <small class="mx-1">{{ l?.details }}</small>
        <h6 class="mx-1 text-muted">
          {{ dateFormatter.format(new Date(l.created_at)) }}
        </h6>
      </el-row>
    </el-card>
    <el-drawer
      v-model="showDeliverOrderDrawer"
      title="Finalizar entrega do pedido"
      direction="btt"
    >
      <span>
        {{ order?.client?.name }}
      </span>
      <el-divider></el-divider>
      <el-row type="flex" justify="space-between">
        <el-button
          @click="finalizeOrder(false) | (showDeliverOrderDrawer = false)"
          type="warning"
          >Cancelar</el-button
        >
        <el-button
          type="primary"
          @click="finalizeOrder(true) | (showDeliverOrderDrawer = false)"
        >
          Finalizar entrega
        </el-button>
      </el-row>
    </el-drawer>
  </div>
</template>
<script>
/** internal dependencies */
import DriverService from "../../../services/drivers";
import OrderService from "../../../services/orders";
import UserService from "../../../services/users";
import { dateFormatter } from "../../../utils/formatters";
import { notifySuccess, notifyError } from "../../../utils/notifiers";
export default {
  name: "DriverHomeComponent",
  computed: {
    Driver() {
      return this.$store.state.user.driver;
    },
    Supplies() {
      return this.supplies?.data || [];
    },
    Travel() {
      return this.travel || [];
    },
    Orders() {
      return this.orders || [];
    },
    Logs() {
      return this.logs?.data || [];
    },
  },
  data() {
    return {
      supplies: null,
      travel: null,
      orders: null,
      selectedOrder: null,
      logs: null,
      hasErrorSupplies: false,
      hasErrorLogs: false,
      hasErrorOrders: false,
      latitude: null,
      longitude: null,
      showDeliverOrderDrawer: false,
      dateFormatter,
      finalizeOrder: null,
    };
  },
  mounted() {
    this.fetchMySupplyOrders();
    this.fetchMyTravels();
    this.fetchMyOrders();
    this.fetchMyLogs();
    this.getAndSavePosition();
  },
  methods: {
    openDeliverOrder(order) {
      const self = this;
      return new Promise(function (resolve) {
        self.finalizeOrder = resolve;
        self.order = order;
        self.showDeliverOrderDrawer = true;
        self.selectedOrder = order;
      });
    },
    async fetchMySupplyOrders() {
      this.hasErrorSupplies = false;

      const { supplies } = await DriverService(this.Driver.uid)
        .Supply()
        .index({ filterOption: "opened" });

      if (supplies) this.supplies = supplies;
      else this.hasErrorSupplies = true;
    },
    async fetchMyLogs() {
      this.hasErrorLogs = false;

      const { logs } = await UserService(this.$store.state.user?.uid)
        .Log()
        .index({ pagination: 1, limit: 5 });

      if (logs) this.logs = logs;
      else this.hasErrorLogs = true;
    },
    async confirmAndCompleteDelivery(order) {
      const shouldComplete = await this.openDeliverOrder(order);

      if (shouldComplete) {
        await this.updateOrder(this.order?.uid, {
          status: "delivered",
          next_status: "completed",
          delivered_at: new Date(),
          latitude: this.latitude,
          longitude: this.longitude,
        });

        this.fetchMyOrders();
      }
    },
    async fetchMyOrders() {
      this.hasErrorOrders = false;

      const { orders } = await OrderService().index({
        driver_id: this.Driver.uid,
        status: "delivery",
      });

      if (orders) this.orders = orders;
      else this.hasErrorOrders = true;
    },
    async fetchMyTravels() {
      const { controls } = await DriverService(this.Driver.uid)
        .Control()
        .index({ status: "not completed", category: "Viagem" });

      if (controls) this.travel = controls?.data?.[0];
    },
    async getAndSavePosition() {
      const { latitude, longitude } = (await this.getCurrentPosition()) || {};

      if (latitude && longitude) {
        this.latitude = latitude;
        this.longitude = longitude;
      } else this.$message.error("Seu navegador não suporta geolocalização");
    },
    async updateOrder(orderId, orderData) {
      const { order, error } = await OrderService(orderId).update(orderData);

      if (order) notifySuccess(order.message);
      else notifyError(error.message);
    },
    async getCurrentPosition() {
      return new Promise(function (resolve, reject) {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition((position) => {
            resolve(position.coords);
          });
        } else {
          reject();
        }
      });
    },
  },
};
</script>
<style scoped>
.item-text {
  font-size: 12px;
  line-height: 32px;
  text-align: start;
  max-width: 70%;
}
.card-header {
  font-weight: 600;
}
</style>