<template>
  <div style="height: 100%">
    <el-row type="flex" :gutter="8">
      <el-col :md="12" :xs="24"
        ><el-card
          style="min-height: 50%"
          shadow="hover"
          v-loading="!userLogs && !hasErrorUserLogs"
          element-loading-text="Carregando ações..."
        >
          <template #header>
            <el-row
              type="flex"
              justify="start"
              style="min-height: 28px; align-items: center"
            >
              <small>ÚLTIMAS AÇÕES</small>
            </el-row>
          </template>
          <el-row type="flex" justify="center" align="center" class="mx-auto">
            <el-col>
              <el-result
                v-if="hasErrorUserLogs"
                icon="error"
                title="Ocorreu um erro"
                sub-title="Não foi possível carregar suas últimas ações"
              >
                <template #extra>
                  <el-button
                    type="primary"
                    @click="() => fetchLogs()"
                    size="medium"
                    >Tentar novamente</el-button
                  >
                </template>
              </el-result>
              <el-empty
                v-else-if="!userLogs?.length"
                description="Nenhuma ação foi realizada"
              ></el-empty>
              <el-row
                type="flex"
                justify="space-between"
                v-else
                v-for="l in userLogs"
                :key="l"
              >
                <small class="mx-1">{{ l?.details }}</small>
                <h6 class="mx-1 text-muted">
                  {{ dateFormatter.format(new Date(l.created_at)) }}
                </h6>
              </el-row>
            </el-col>
          </el-row>
        </el-card>
        <el-card
          style="margin-top: 8px"
          shadow="hover"
          v-loading="!userLogs && !hasErrorUserLogs"
          element-loading-text="Carregando notificações..."
        >
          <template #header>
            <el-row
              type="flex"
              justify="start"
              style="min-height: 28px; align-items: center"
            >
              <small>NOTIFICAÇÕES</small>
            </el-row>
          </template>
          <el-row type="flex" justify="center" align="center" class="mx-auto">
            <el-col>
              <el-empty description="Nenhuma notificação"></el-empty>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :md="12" :xs="24"
        ><el-card>
          <template #header>
            <el-row type="flex" justify="space-between">
              <small class="mx-auto">MINHA CONTA</small>
              <el-button
                plain
                @click="() => redefinePassword()"
                size="mini"
                type="default"
                >ALTERAR SENHA</el-button
              >
            </el-row>
          </template>
          <el-row type="flex" justify="center" align="center" class="mx-auto">
            <el-col>
              <el-row type="flex" justify="space-between">
                <h5 class="mx-1 text-muted">Nome:</h5>
                <h6 class="mx-1">
                  {{ $store.state?.user?.name }}
                </h6>
              </el-row>
              <el-row type="flex" justify="space-between">
                <h5 class="mx-1 text-muted">Usuário:</h5>
                <h6 class="mx-1">
                  {{ $store.state?.user?.username }}
                </h6>
              </el-row>
              <el-row type="flex" justify="space-between">
                <h5 class="mx-1 text-muted">Email:</h5>
                <h6 class="mx-1">
                  {{ $store.state?.user?.email }}
                </h6>
              </el-row>
              <el-row type="flex" justify="space-between">
                <h5 class="mx-1 text-muted">Impressora:</h5>
                <h6 class="mx-1">
                  <el-select
                    v-model="selectedPrinter"
                    value-key="uid"
                    @change="saveSelectedPrinter"
                  >
                    <el-option
                      v-for="printer in Printers"
                      :key="printer.uid"
                      :label="printer.name"
                      :value="printer.uid"
                    ></el-option>
                  </el-select>
                </h6>
              </el-row>
            </el-col>
          </el-row>
        </el-card>
        <el-card
          style="min-height: 50%; margin-top: 8px"
          shadow="hover"
          v-loading="!userLogs && !hasErrorUserLogs"
          element-loading-text="Carregando eventos..."
        >
          <template #header>
            <el-row type="flex" justify="space-between">
              <small class="mx-auto">MEUS EVENTOS</small>
              <el-button
                type="default"
                plain
                @click="$router.push('/agenda')"
                size="mini"
                >EXIBIR MAIS</el-button
              >
            </el-row>
          </template>
          <el-row type="flex" justify="center" align="center" class="mx-auto">
            <el-col>
              <el-result
                v-if="hasErrorUserLogs"
                icon="error"
                title="Ocorreu um erro"
                sub-title="Não foi possível carregar seus eventos"
              >
                <template #extra>
                  <el-button
                    type="primary"
                    @click="() => fetchEvents()"
                    size="medium"
                    >Tentar novamente</el-button
                  >
                </template>
              </el-result>
              <el-empty
                v-else-if="!userEvents?.length"
                description="Nenhum evento cadastrado"
              ></el-empty>
              <el-row
                type="flex"
                justify="start"
                v-else
                v-for="(l, k) in CompiledEvents"
                :key="k"
              >
                <el-col>
                  <el-divider content-position="left"
                    ><div class="event-date">
                      {{ dateFormatterNotTime.format(new Date(k)) }}
                    </div>
                  </el-divider>

                  <el-row
                    type="flex"
                    justify="space-between"
                    align="center"
                    class="mt-1"
                    v-for="e in l"
                    style="align-items: center"
                    :key="e"
                  >
                    <el-row
                      type="flex"
                      justify="space-around"
                      style="align-items: center"
                      :gutter="4"
                    >
                      <el-tooltip placement="right">
                        <el-tag :type="getEventType(e.priority)" class="mr-1">
                          #
                        </el-tag>
                        <template #content>
                          <div>Visibilidade: {{ e.visibility_formatted }}</div>
                          <div>Prioridade: {{ e.priority_formatted }}</div>
                          <div>
                            Inicia em:
                            {{
                              dateFormatterNotTime.format(new Date(e.start_at))
                            }}
                          </div>
                          <div>
                            Finaliza em:
                            {{
                              dateFormatterNotTime.format(new Date(e.expire_at))
                            }}
                          </div>
                        </template>
                      </el-tooltip>

                      <small class="event-title mx-auto">
                        {{ e.title }}
                      </small>
                    </el-row>
                    <small class="event-details">
                      {{ e.details }}
                    </small>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { ElNotification, ElMessageBox } from "element-plus";
import SystemService from "../../../services/system";
import UserService from "../../../services/users";
import { dateFormatter, currencyFormatter } from "../../../utils/formatters";

export default {
  name: "MainHomeComponent",
  computed: {
    Printers() {
      return this.printers || [];
    },
    CompiledEvents() {
      return this.userEvents?.reduce((t, e) => {
        const _d = new Date(e.start_at);
        _d.setHours(0);
        _d.setMinutes(0);
        _d.setSeconds(0);
        _d.setMilliseconds(0);
        if (t[_d.toISOString()]) t[_d.toISOString()].push(e);
        else t[_d.toISOString()] = [e];

        return t;
      }, {});
    },
  },
  methods: {
    setSelectedPrinter(user) {
      if (user?.printer) this.selectedPrinter = user?.printer?.uid;
    },
    async saveSelectedPrinter() {
      if (this.selectedPrinter !== this.$store.state.user.printer?.uid) {
        const { user } = await UserService(this.$store.state.user.uid).update({
          printer_id: this.selectedPrinter,
        });

        if (user) {
          ElNotification({
            title: "Impressora alterada",
            message: "Impressora alterada com sucesso",
            type: "success",
          });
        }
      }
    },
    getLastMonths(months) {
      const dates = [];
      const now = new Date();
      while (months--) {
        dates.push(now.toISOString());
        now.setMonth(now.getMonth() - 1);
      }

      return dates;
    },
    async fetchPrinters() {
      const { printers } = await SystemService().Printers().index();

      if (printers) this.printers = printers;
    },
    getEventType(type) {
      if (type === "urgent") return "danger";
      if (type === "warning") return "warning";
      return "primary";
    },
    fetchEvents() {
      this.userEvents = null;
      this.hasErrorUserEvents = false;
      const url = new URL(`${this.$store.state.apiUrl}events`);
      url.search = new URLSearchParams({
        start_at: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 1,
          23,
          59
        ),
        expire_at: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + 5
        ),
      });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(
          (json) => (this.userEvents = json || []),
          () => (this.hasErroruserEvents = true)
        );
    },
    async redefinePassword() {
      let ps1, ps2;
      try {
        ps1 = await this.askForPassword("Informe a nova senha");

        while (ps1 != ps2) ps2 = await this.askForPassword("Repita a senha");
      } catch (e) {
        return;
      }

      this.updateUser({
        uid: this.$store.state.user?.uid,
        password: ps1,
        password_confirmation: ps2,
      });
    },
    updateUser(user) {
      if (user)
        fetch(`${this.$store.state.apiUrl}users/${user.uid}`, {
          credentials: "include",
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else throw response.json();
          })
          .then(() => {
            this.$notify({
              title: "A sua senha foi redefinida",
              type: "success",
              position: "bottom-right",
            });
          })
          .catch(async (e) => {
            this.$notify({
              title: "Ocorreu um erro",
              type: "error",
              message: (await e)?.message,
              position: "bottom-right",
            });
          });
    },
    async askForPassword(title, description) {
      return await ElMessageBox.prompt(description, title, {
        type: "warning",
        inputType: "password",
        confirmButtonText: "Próximo",
        cancelButtonText: "Cancelar",
      }).then((v) => v.value);
    },
    fetchLogs() {
      this.userLogs = null;
      this.hasErrorUserLogs = false;
      const url = new URL(
        `${this.$store.state.apiUrl}users/${this.$store.state.user?.uid}/logs`
      );
      url.search = new URLSearchParams({ pagination: 1 });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(
          (json) => (this.userLogs = json?.data || []),
          () => (this.hasErrorUserLogs = true)
        );
    },
  },
  data() {
    return {
      userEvents: null,
      hasErrorUserEvents: false,
      userLogs: null,
      hasErrorUserLogs: false,
      printers: null,
      dateFormatter,
      selectedPrinter: null,
      dateFormatterNotTime: new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "long",
      }),
      currencyFormatter,
    };
  },
  mounted() {
    this.fetchLogs();
    this.fetchEvents();
    this.fetchPrinters();
    this.setSelectedPrinter(this.$store.state.user);
  },
};
</script>