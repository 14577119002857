<template>
  <driver-home v-if="UserIsDriver"></driver-home>
  <main-home v-else></main-home>
</template>
<script>
/** internal dependencies */
import MainHome from "../components/pages/home/main.vue";
import DriverHome from "../components/pages/home/driver.vue";
export default {
  components: { MainHome, DriverHome },
  name: "home",
  computed: {
    UserIsDriver() {
      return !!this.$store?.state?.user?.driver;
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.mx-auto {
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
}
.mx-1 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.text-muted {
  color: grey;
}
.remove-padding {
  margin-left: -20px;
  margin-right: -20px;
  width: 100%;
}
.event-date {
  font-weight: bold;
}
.event-title {
  font-weight: 500;
  color: black;
}
.event-details {
  font-size: small;
  color: grey;
}
.mt-1 {
  margin-top: 4px;
}
.mr-1 {
  margin-right: 4px;
}
</style>